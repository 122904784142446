/***** COMMON STYLES *****/

/* Preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.preloader.pre-hide {
  visibility: hidden;
  opacity: 0;
}

.preloader-content {
  width: 100%;
  max-width: 25rem;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 5rem;
}

.preloader-content img {
  width: 100%;
  max-width: 4.5rem;
}

.preloader-content #pre {
  width: 4rem;
  margin-top: 1.5rem;
}

.preloader-content h5 {
  font-family: var(--fmedium);
  font-size: var(--font-14);
  text-transform: uppercase;
  margin: 2rem 0 3rem;
  letter-spacing: 2px;
  animation: flash 3000ms infinite linear forwards;
}

.preloader-content h6 {
  font-family: var(--freg);
  font-size: var(--font-28);
  color: var(--primary-blue);
  margin: 1rem 0 0;
}

.preloader-content h6 span {
  font-family: inherit;
  color: var(--primary-red);
}

.pre-progress {
  width: 100%;
  height: 0.5rem;
  background: var(--light-ee);
  isolation: isolate;
  margin: 3rem 0 0;
  border-radius: 16px;
}

.pre-progress .prog-line {
  width: 0;
  height: 100%;
  background: var(--primary-red);
  border-radius: 16px;
  animation: loading 2100ms linear forwards;
  animation-delay: 300ms;
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Header */

.header-top {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 1rem;
  background: var(--primary-red);
  min-height: 4rem;
  max-height: 4rem;
}

.header-top .header-content {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.header-top .header-content ul.header-top-right {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
}

.header-top .header-content ul.header-top-right li {
  padding-inline: 1.25rem;
}

.header-top .header-content ul.header-top-right li:last-child {
  border-right: none;
  padding-right: 0;
}

.header-top .header-content ul.header-top-right span {
  font-size: var(--font-10);
  color: var(--white);
}

.header-top .header-content a.header-links {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
}

.header-top .header-content a.header-links i {
  font-size: var(--font-20);
}

.header-top .header-content h6 {
  color: var(--white);
  margin: 0 0 0 1rem;
  line-height: 1.5;
}

.header-top .header-content a.header-links,
.header-top .header-content h6 {
  font-family: var(--fsemibold);
  font-size: 1.5rem;
}

header.header {
  position: fixed;
  top: 4rem;
  z-index: 10;
  width: 100%;
  padding: 1.5rem 0;
  transition: 0.1s linear;
  background: var(--white);
}

header.header.scrolled {
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);
}

header.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

header.header .logo-link {
  display: flex;
  align-items: center;
  gap: 2px;
}

header.header .logo-link h6 {
  font-family: var(--freg);
  font-size: var(--font-28);
  color: var(--primary-blue);
  line-height: 1;
  letter-spacing: -0.8px;
  margin: 1rem 0;
}

header.header .logo-link h6 span {
  font-family: inherit;
  color: var(--primary-red);
}

header.header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
}

header.header .logo img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

header.header .middle-menu-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}

header.header ul.header-menu-items {
  display: flex;
  align-items: center;
  gap: 30px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

header.header ul.header-menu-items a.menu-link {
  line-height: 1;
}

header.header ul.header-menu-items li {
  font-family: var(--fmedium);
  font-size: 1.5rem;
  color: var(--black-2e);
  padding: 0;
  border-radius: 4px;
  transition: 200ms ease;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

header.header ul.header-menu-items li:hover {
  color: var(--primary-red);
}

header.header ul.header-menu-items a.menu-link.active {
  position: relative;
  transition: 0.2s ease;
}

header.header ul.header-menu-items a.menu-link.active li {
  font-family: var(--fsemibold);
  color: var(--primary-red);
}

header.header ul.header-menu-items a.menu-link::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 0.1rem;
  background: var(--primary-red);
  width: 2.4rem;
  height: 0.35rem;
  transition: 150ms ease transform;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}

header.header ul.header-menu-items a.menu-link.active::before {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

header.header .right-contact-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

header.header .right-contact-options .home-contact-btn {
  font-family: var(--fbold);
  font-size: var(--font-16);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 0.5rem 1.5rem;
  outline-color: transparent;
  border: none;
  border-radius: 8px;
  background: var(--neon);
  transition: 0.2s ease;
  transform: scale(1);
  min-height: 4rem;
  animation: pulse 1000ms ease infinite;
}

header.header .right-contact-options .home-contact-btn:hover {
  transform: scale(0.95);
}

header.header .right-contact-options .home-contact-btn img {
  width: 2rem;
  max-width: 2rem;
  min-width: 2rem;
}

@keyframes pulse {
  0% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
}

/* End of Header */

/* Floating Menu */

section.floating-menu {
  position: fixed;
  top: 40%;
  left: 1rem;
  z-index: 99;
}

section.floating-menu ul {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  list-style-type: none;
  padding: .5rem;
  margin: 0;
  background: var(--neon);
  border-radius: .8rem;
}

section.floating-menu ul li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  min-width: 3.75rem;
  min-height: 3.75rem;
  max-width: 3.75rem;
  max-height: 3.75rem;
  line-height: 3.75rem;
  border-radius: 20rem;
  /* box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  border-left: none;
  transition: 0.1s linear all;
}

section.floating-menu ul li:hover {
  border-radius: 20rem 0 0 20rem;
  border: 1px solid var(--black-21);
  border-right: none;
  background: var(--primary-blue);
  color: var(--white);
}
section.floating-menu ul li:hover i {
  color: var(--white);
}

section.floating-menu ul li:hover::before {
  content: attr(data-content);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2.75rem;
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--white);
  padding: 0.25rem 1.75rem 0.25rem 2.25rem;
  width: max-content;
  min-width: 100%;
  background: inherit;
  border: 1px solid var(--black-21);
  border-radius: 0 8px 8px 0;
  text-align: center;
  line-height: 1.25;
  border-left: none;
  min-height: 3.75rem;
}

section.floating-menu ul li i {
  font-size: var(--font-20);
  color: var(--black);
}

section.floating-menu ul li i.bxl-whatsapp {
  font-size: var(--font-24);
}

/* End of Floating Menu */

/* Breadcrumbs */

section.breadcrumbs-wrapper {
  margin: 0 0 1rem 0;
  background: var(--light-ee);
  padding: 1rem 0;
  position: sticky;
  top: calc(9rem + 4rem);
  z-index: 10;
}

section.breadcrumbs-wrapper .container.bdc h6 {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: var(--font-16);
  text-transform: initial;
  letter-spacing: normal;
  margin: 0 0 0 0.65rem;
}

section.breadcrumbs-wrapper .container.bdc h6 a {
  font-family: var(--flight);
  color: var(--black-21);
  line-height: 1;
}

section.breadcrumbs-wrapper .container.bdc h6 i {
  font-size: var(--font-18);
  color: var(--black-21);
  margin-top: 0.2rem;
  line-height: 1;
}

section.breadcrumbs-wrapper .container.bdc h6 span {
  font-family: var(--fsemibold);
  color: var(--primary-red);
}

/* End of Breadcrumbs */

.consulting-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin: 1.5rem 0;
  padding: 3.5rem;
  border-radius: 16px;
  background: linear-gradient(
      to right,
      rgba(20, 29, 54, 0.9),
      rgba(0, 36, 137, 0.5)
    ),
    url("../../../images/bg_consulting.jpg");
  background-size: cover;
  background-position: center 40%;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}

.consulting-wrap:hover {
  background: linear-gradient(
      to right,
      rgba(20, 29, 54, 1) 30%,
      rgba(0, 36, 137, 1)
    ),
    url("../../../images/bg_consulting.jpg");
  background-size: cover;
  background-position: center 15%;
  background-repeat: no-repeat;
}

.consulting-wrap .cw-left {
  max-width: 70%;
}

.consulting-wrap h4 {
  font-family: var(--fblack);
  font-size: var(--font-28);
  color: var(--white);
  margin: 1.5rem 0;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1.5;
}

.consulting-wrap h4 span {
  font-family: inherit;
  color: var(--neon);
}

.consulting-wrap h5 {
  font-family: var(--fregular);
  font-size: var(--font-20);
  color: var(--light-fa);
  max-width: 80%;
  line-height: 1.5;
}

.consulting-wrap img {
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  max-width: 6rem;
  max-height: 6rem;
}

.consulting-wrap button.consult-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: var(--fbold);
  font-size: var(--font-16);
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-right: 2rem;
  background: var(--neon);
  border: 2px solid var(--white);
  transition: 0.18s ease;
  transform: scale3d(1, 1, 1);
  animation: pulse 1000ms ease infinite;
}

.consulting-wrap button.consult-btn i {
  font-size: var(--font-18);
}

.consulting-wrap button.consult-btn:hover {
  background: var(--white);
  color: var(--black-21);
}

@keyframes pulse {
  0% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
}

/* Go To Top */

.go-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 3rem;
  bottom: 2rem;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  border: 1px solid var(--white);
  background: var(--primary-blue);
  border-radius: 50%;
  opacity: 0.5;
  z-index: 999;
  transition: 0.18s ease;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.go-to-top.show {
  pointer-events: auto;
  opacity: 0.5;
  visibility: visible;
}

.go-to-top:hover {
  opacity: 1;
}

.go-to-top i {
  font-size: var(--font-24);
  color: var(--white);
}

/* End of Go To Top */

/* Request a call */

.contact-modal-wrap .contact-form-wrap {
  margin: 0;
  padding: 1rem;
}

.contact-modal-wrap .modal-header {
  background: var(--neon);
  border-bottom: 1px solid var(--black-21);
}

.contact-modal-wrap .modal-title {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black-21);
  margin: 0.5rem 1rem;
  line-height: 1;
}

.contact-modal-wrap .modal-header button.btn-close {
  --bs-btn-close-bg: url("../../../images/closeIcon.svg");
  font-size: var(--font-28);
  color: var(--white);
  margin-right: 0;
  opacity: 0.9 !important;
}

.contact-modal-wrap .modal-header button.btn-close:focus {
  box-shadow: none;
}

.contact-modal-wrap .success-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  padding: 1.25rem 1rem;
  background: #2ab06f;
  color: var(--white);
  border-radius: 5px;
  margin: 0 0 1rem 0;
}

.contact-modal-wrap .success-wrap i {
  font-size: var(--font-24);
}

.contact-modal-wrap .contact-form-wrap form.contact-form .contact-input {
  width: 100%;
  padding: 0.75rem 0;
}

.contact-modal-wrap .contact-form-wrap form.contact-form .contact-input label {
  font-family: var(--fsemibold);
  font-size: var(--font-12);
  margin-bottom: 0.75rem;
  letter-spacing: -0.01rem;
  text-transform: uppercase;
}

.contact-modal-wrap .contact-form-wrap form.contact-form .contact-input input {
  font-family: var(--fmedium);
  font-size: var(--font-14);
  color: var(--black-21);
  width: 100%;
  padding: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  outline-color: transparent;
  border: 2px solid var(--light-e0);
  border-radius: 5px;
  transition: 0.18s ease;
  background: rgba(26, 33, 52, 0.03);
}

.contact-modal-wrap
  .contact-form-wrap
  form.contact-form
  .contact-input
  input::placeholder {
  color: var(--black-75);
}

.contact-modal-wrap
  .contact-form-wrap
  form.contact-form
  .contact-input
  input:focus {
  border-color: var(--primary-blue);
  outline-color: var(--primary-blue);
  background: var(--white);
}

.contact-modal-wrap
  .contact-form-wrap
  form.contact-form
  .contact-input
  textarea {
  font-family: var(--fmedium);
  font-size: var(--font-14);
  color: var(--black-21);
  width: 100%;
  padding: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  outline-color: transparent;
  border: 2px solid var(--light-e0);
  border-radius: 5px;
  transition: 0.18s ease;
  background: rgba(26, 33, 52, 0.03);
  resize: none;
}

.contact-modal-wrap
  .contact-form-wrap
  form.contact-form
  .contact-input
  textarea:focus {
  border-color: var(--primary-blue);
  outline-color: var(--primary-blue);
  background: var(--white);
}

.contact-modal-wrap .contact-form-wrap form.contact-form .contact-input span {
  display: inline-block;
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  color: var(--primary-red);
  margin-top: 0.25rem;
}

.contact-modal-wrap .contact-form-wrap form.contact-form button.submit-form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid currentColor;
  color: var(--black);
  border-radius: 5px;
  margin: 0;
  background: none;
  overflow: hidden;
  letter-spacing: -0.01rem;
  transition: 0.18s ease;
}

.contact-modal-wrap .contact-form-wrap form.contact-form button.submit-form i {
  font-size: var(--font-20);
}

.contact-modal-wrap
  .contact-form-wrap
  form.contact-form
  button.submit-form:hover {
  background: var(--neon);
  color: var(--black);
}

/* End of Request a call */

/* Footer */

footer.footer {
  position: relative;
  padding: 4.5rem 0 0;
  isolation: isolate;
}

footer.footer .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
}

footer.footer .logo img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

footer.footer .logo-text h5 {
  font-family: var(--fbold);
  font-size: var(--font-18);
  color: var(--black-2e);
  margin: 1.5rem 0 1rem;
}

footer.footer .logo-text p {
  font-size: var(--font-16);
  color: var(--black-61);
  max-width: 90%;
  line-height: 1.65;
}

footer.footer h4 {
  font-family: var(--fextrabold);
  font-size: var(--font-14);
  color: var(--primary-blue);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 1.5rem 0;
}

footer.footer ul.footer-contact-info-wrap,
footer.footer ul.footer-links-info-wrap {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

footer.footer ul.footer-contact-info-wrap li a {
  display: inline-flex;
  align-items: center;
  gap: 15px;
  padding: 1rem 0;
  cursor: pointer;
}

footer.footer ul.footer-contact-info-wrap li i {
  font-size: var(--font-18);
  color: var(--black-21);
  transition: 0.2s ease;
}

footer.footer ul.footer-contact-info-wrap li a:hover i {
  color: var(--primary-red);
}

footer.footer ul.footer-contact-info-wrap li h6 {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--black-21);
  margin: 0;
  line-height: 1.5;
  transition: 0.18s ease;
}

footer.footer ul.footer-contact-info-wrap li a:hover h6 {
  color: var(--primary-red);
}

footer.footer ul.footer-links-info-wrap li a {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-16);
  color: var(--black-21);
  padding: 0.75rem 0;
  transition: 0.18s ease;
  letter-spacing: -0.02rem;
}

footer.footer ul.footer-links-info-wrap li a:hover {
  color: var(--primary-red);
}

footer.footer ul.footer-links-info-wrap li i {
  margin-top: 0.2rem;
  transform: translateX(-2rem);
  opacity: 0;
  visibility: none;
  transition: 0.18s ease;
}

footer.footer ul.footer-links-info-wrap li a:hover i {
  transform: translateX(0.5rem);
  opacity: 1;
  visibility: visible;
}

footer.footer ul.footer-links-info-wrap li span {
  font-family: var(--fsemibold);
  color: var(--primary-red);
}

footer.footer ul.footer-links-info-wrap li h6 {
  font-size: var(--font-16);
}

footer.footer ul.footer-social-links {
  display: flex;
  align-items: center;
  gap: 12px;
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
}

footer.footer ul.footer-social-links li {
  cursor: pointer;
  line-height: 1;
}

footer.footer ul.footer-social-links li i {
  font-size: var(--font-28);
  color: var(--black-21);
}

footer.footer ul.footer-social-links li img {
  width: 2rem;
  max-width: 2rem;
}

footer.footer .footer-bottom {
  padding: 2rem 0;
  margin: 3rem 0 0;
  background: var(--light-f5);
}

footer.footer .footer-bottom p {
  font-family: var(--fsemibold);
  color: var(--black-21);
  text-align: center;
  margin: 0;
}

footer.footer .footer-bottom a {
  font-family: inherit;
  color: var(--primary-red);
}

footer.footer .footer-bottom span {
  padding-inline: 0.5rem;
}

footer .connect-input {
  padding: 1.25rem 0;
}

footer .connect-input .connect-inp {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 1rem 0;
}

footer .connect-input input {
  font-family: var(--fmedium);
  font-size: var(--font-14);
  width: 100%;
  border: 2px solid var(--black-75);
  padding: 0.65rem 1.25rem;
  border-radius: 5px;
  outline: none;
  transition: 0.15s ease;
  min-height: 4rem;
  background: var(--light-fa);
}

footer .connect-input input:focus {
  border-color: var(--black);
  background: var(--white);
}

footer .connect-input button.footer-connect-btn {
  font-family: var(--fsemibold);
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  min-height: 4rem;
  border-radius: 5px;
  background: var(--neon);
  transition: 0.18s ease;
  min-width: 14rem;
}

footer .connect-input button.footer-connect-btn:hover {
  background: var(--black-21);
  color: var(--white);
}

/* End of Footer */

/* Page Not Found */

section.error-404-wrapper.common-wrapper .error-404-content {
  text-align: center;
  margin: 0 auto;
}

section.error-404-wrapper.common-wrapper .error-404-content h3 {
  font-family: var(--fbold);
  font-size: 5rem;
  margin: 3rem 0 0;
}

section.error-404-wrapper.common-wrapper .error-404-content h5 {
  font-family: var(--fmedium);
  font-size: var(--font-18);
  margin: 1rem 0;
}

section.error-404-wrapper.common-wrapper .error-404-content img {
  width: 50rem;
  max-width: 100%;
}

/* End of Page Not Found */

/***** END OF COMMON STYLES *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  /* Header */

  .header-top {
    height: auto;
    max-height: 100%;
    z-index: 11;
    padding: 0.75rem;
  }

  .header-top .header-content {
    align-items: center !important;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .header-top .header-content h6 {
    display: none;
    font-size: var(--font-12);
    margin: 0;
  }

  .header-top .header-content ul.header-top-right {
    margin: 0;
    padding: 0;
    gap: 6px;
    flex-wrap: wrap !important;
  }

  .header-top .header-content ul.header-top-right li {
    padding-inline: 0;
  }

  .header-top .header-content ul.header-top-right li.mob-hide,
  .header-top .header-content ul.header-top-right span.mob-hide {
    display: none;
  }

  .header-top .header-content a.header-links,
  .header-top .header-content h6.header-links {
    display: flex;
    gap: 5px;
    font-size: 1.25rem;
  }

  .header-top .header-content a.header-links i {
    font-size: var(--font-12);
  }

  header.header {
    padding-inline: 1rem;
  }

  header.header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    max-width: 4.5rem;
    min-height: 4.5rem;
    max-height: 4.5rem;
  }

  header.header .logo-link h6 {
    font-size: var(--font-20);
  }

  header.header .middle-menu-items .header-menu-items {
    width: 100%;
    gap: 15px;
    position: fixed;
    top: 11.25rem;
    left: 0;
    background: var(--white);
    padding: 1rem 2rem 1.75rem;
    min-height: 4rem;
    max-height: 4rem;
  }

  header.header .middle-menu-items .header-menu-items a.menu-link li {
    font-size: 1.3rem;
  }

  header.header ul.header-menu-items a.menu-link::before {
    height: 0.2rem;
    bottom: -0.65rem;
  }

  header.header .right-contact-options .home-contact-btn {
    padding: 0.5rem 1.5rem;
    font-size: var(--font-14);
    min-height: auto;
    margin-right: 0.5rem;
  }

  /* End of Header */

  /* Breadcrumbs */

  section.breadcrumbs-wrapper {
    top: calc(7.25rem + 4rem + 4rem);
  }

  section.breadcrumbs-wrapper .container.bdc h6 {
    font-size: var(--font-14);
    padding-left: 0.7rem;
  }

  /* End of Breadcrumbs */

  /* Common Consulting */

  .consulting-wrap {
    padding: 1.5rem;
    flex-direction: column;
    gap: 15px;
    margin: 1rem;
    background-position: 65%;
  }
  .consulting-wrap:hover {
    background-position: 0;
  }

  .consulting-wrap .cw-left {
    max-width: 100%;
  }

  .consulting-wrap h4 {
    font-size: 2.25rem;
  }

  .consulting-wrap h5 {
    font-size: var(--font-16);
    max-width: 100%;
  }

  .consulting-wrap button.consult-btn {
    width: 100%;
    margin: 1rem 0;
  }

  .consulting-wrap a {
    width: 100%;
    display: block;
  }

  /* End of Common Consulting */

  /* Footer */

  footer.footer {
    padding: 3rem 0 0;
  }
  footer.footer .container {
    padding-inline: 1.75rem;
  }
  footer.footer .logo {
    width: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    max-width: 4.5rem;
    min-height: 4.5rem;
    max-height: 4.5rem;
  }
  footer.footer .logo-text h5 {
    font-size: var(--font-16);
  }
  footer.footer .logo-text p {
    font-size: var(--font-14);
    max-width: 100%;
  }

  footer.footer ul.footer-contact-info-wrap li h6,
  footer.footer ul.footer-links-info-wrap li h6 {
    font-size: var(--font-14);
  }

  footer.footer ul.footer-links-info-wrap li a {
    font-size: var(--font-14);
  }

  footer.footer .connect-input {
    border-top: 1px solid var(--light-e0);
    margin-top: 1rem;
  }

  footer.footer .connect-input h4 {
    margin-top: 0.5rem;
  }

  footer .connect-input .connect-inp {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  footer.footer .footer-bottom {
    margin-top: 1rem;
  }

  footer.footer .footer-bottom p.copy-text {
    font-size: var(--font-12);
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
  }

  /* End of Footer */

  .go-to-top {
    right: 2rem;
    bottom: 4.5rem;
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;
    max-width: 3.75rem;
    max-height: 3.75rem;
  }
  .go-to-top i {
    font-size: var(--font-18);
  }
}

/***** END OF MEDIA QUERIES  *****/
