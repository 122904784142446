/***** CONTACT US *****/

section.contact-wrapper .common-header.contact-header h6 {
  margin-left: 0.25rem;
}

section.contact-wrapper .contact-info-item-wrap {
  margin: 6rem 0 2rem;
  max-width: 95%;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item {
  background: var(--light-fa);
  margin-bottom: 2rem;
  padding: 2.5rem;
  height: calc(100% - 2rem);
  transition: 0.19s ease;
  border-top: 4px solid transparent;
  border-radius: 4px;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item:hover {
  background: var(--white);
  box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.1);
  border-top: 4px solid var(--primary-red);
  animation: changeColor 1500ms forwards ease;
}

@keyframes changeColor {
  from {
    border-top: 4px solid var(--primary-red);
    box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.1);
  }
  to {
    border-top: 4px solid #228c22;
    box-shadow: 0 15px 25px 0 rgba(34, 140, 34, 0.1);
  }
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item i {
  font-size: var(--font-26);
  color: var(--primary-blue);
  transition: 0.19s ease;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item:hover i {
  transition-delay: 500ms;
  color: #228c22;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item h5 {
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  color: var(--black-75);
  margin: 1.5rem 0;
  text-transform: uppercase;
  letter-spacing: 0.19rem;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item:hover h5 {
  transition-delay: 500ms;
  color: #228c22;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item h4 {
  font-family: var(--fbold);
  font-size: var(--font-18);
  color: var(--black);
  margin: 0;
  line-height: 1.35;
}

section.contact-wrapper .contact-info-item-wrap .contact-info-item img {
  width: 2.8rem;
  max-width: 2.8rem;
  min-width: 2.8rem;
}

section.contact-wrapper .contact-right-img {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 2rem 0;
  padding: 0;
  overflow: hidden;
}
/* 
section.contact-wrapper .contact-right-img::before {
  content: "";
  position: absolute;
  top: 3rem;
  left: -4.5rem;
  z-index: 1;
  background: var(--primary-red);
  width: 10rem;
  height: 0.75rem;
} */

section.contact-wrapper .contact-right-img img {
  width: 100%;
  max-width: 100%;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.contact-wrapper .contact-right-img:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.contact-wrapper .map-wrap {
  margin: 2rem 0;
}

/***** END OF CONTACT US *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.contact-wrapper .contact-header {
    margin: 3rem 0 2rem;
    padding: 1rem;
  }
  section.contact-wrapper .contact-info-item-wrap {
    margin: 3rem 0 0;
    padding: 1rem;
    max-width: 100%;
  }
  section.contact-wrapper .contact-right-img {
    padding: 1rem;
    margin: 0;
  }
  section.contact-wrapper .map-wrap {
    padding: 1rem;
    margin: 1rem 0;
  }
}

/***** END OF MEDIA QUERIES  *****/
