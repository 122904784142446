/***** HOME STYLES *****/

section.home-wrapper {
  position: relative;
  z-index: 1;
  /* padding: calc(9rem + 3rem) 0 6rem; */
  padding: 12.5% 0 6rem;
  background-image: linear-gradient(
      to right,
      rgba(2, 0, 69, 0.95),
      rgba(180, 0, 0, 0.5) 80%
    ),
    url("../../../images/herobg.jpg");
  background-size: 110%;
  background-repeat: no-repeat;
  /* background-position: left -100%; */
  isolation: isolate;
  overflow: hidden;
}

section.home-wrapper video.home-wrapper-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: max-content;
  aspect-ratio: 16/9;
}
/* 
section.home-wrapper .home-wrapper-video-overlay {
  position: absolute;
  top: 13rem;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(3, 0, 97, 0.9),
    rgba(169, 0, 0, 0.6)
  );
  background-position: center;
  background-size: 100%;
} */

section.home-wrapper .text-content {
  position: relative;
  padding: 3rem 0.5rem;
  isolation: isolate;
}

section.home-wrapper .text-content h1 {
  font-family: var(--fextrabold);
  font-size: 3rem;
  color: var(--white);
  letter-spacing: 0.02rem;
  margin: 1rem 0;
  max-width: 90%;
  line-height: 1.4;
}

section.home-wrapper .text-content h1 span {
  position: relative;
  font-family: var(--fextrabold);
  color: var(--neon);
  padding-inline: 1rem;
  letter-spacing: 0.02rem;
}

section.home-wrapper .text-content h1 span[data-red] {
  color: var(--red);
}

section.home-wrapper .text-content span[data-testid="react-typed"] span {
  font-family: var(--fextrabold);
  font-size: 5rem;
  color: var(--primary-blue);
  background: var(--neon);
  padding-inline: 1rem;
}

section.home-wrapper
  .text-content
  span[data-testid="react-typed"]
  span.typed-cursor {
  background: none;
  color: var(--white);
}

/* Home Security */

.home-security-banner {
  padding: 3rem 2rem;
  border: 1px solid var(--white);
  border-radius: 0 0 16px 16px;
  background: linear-gradient(
    to right,
    rgba(5, 10, 24, 0.98) 30%,
    rgba(0, 21, 79, 0.9)
  );
  color: var(--white);
}

.home-security-banner .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-security-banner .hsb-left {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.home-security-banner img {
  width: 7rem;
}

.home-security-banner span {
  font-family: var(--fmedium);
  font-size: var(--font-12);
}

.home-security-banner h4 {
  font-family: var(--fbold);
  font-size: var(--font-24);
  color: var(--neon);
}

.home-security-banner h6 {
  font-family: var(--fregular);
  font-size: var(--font-16);
}

.home-security-banner .hsb-right {
  width: 30%;
  margin: 0 auto;
  text-align: center;
}

.home-security-banner .hsb-right button {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  border-radius: 3rem;
  padding: 0.75rem 3rem;
  border: 1px solid var(--white);
  outline: none;
  background: none;
  color: var(--white);
  transition: 200ms ease;
}

.home-security-banner .hsb-right button:hover {
  background: var(--white);
  color: var(--black-21);
}

section.home-wrapper .text-content h6 {
  font-family: var(--fmedium);
  font-size: var(--font-20);
  color: var(--white);
  margin: 20px 0;
  line-height: 1.7;
  max-width: 80%;
}

section.home-wrapper .hero-cta-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 1.5rem 0 1rem;
  padding: 0;
  border-radius: 8px;
}

section.home-wrapper .hero-cta-wrap input.hero-email-input {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--black);
  margin: 0;
  outline: none;
  border: 2px solid var(--black-2e);
  background: var(--light-fa);
  padding: 1rem 2rem;
  min-width: 30rem;
  border-radius: 8px;
  transition: 0.19s ease;
}

section.home-wrapper .hero-cta-wrap input.hero-email-input:focus {
  border-color: var(--black-21);
  background: var(--white);
}

section.home-wrapper .hero-cta-wrap input.hero-email-input::placeholder {
  color: var(--black-75);
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--fbold);
  font-size: var(--font-16);
  border-radius: 8px;
  border: none;
  background: var(--neon);
  color: var(--black-21);
  line-height: 1;
  padding: 1rem 2rem;
  transition: 0.19s ease;
  min-height: 4.5rem;
  max-height: 4.5rem;
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn.whatsapp {
  /* background: #67ff9e; */
  background: var(--white);
  color: var(--black-21);
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn i {
  font-size: var(--font-20);
  margin-top: 0.3rem;
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn.whatsapp img {
  width: 2rem;
  max-width: 2rem;
  min-width: 2rem;
  color: var(--white);
  margin-top: 0.2rem;
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn:hover {
  background: var(--primary-blue);
  color: var(--white);
  /* border-color: var(--neon); */
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn.whatsapp:hover {
  background: var(--primary-blue);
  color: var(--white);
}

section.home-wrapper .hero-cta-wrap button.hero-cta-btn:hover i {
  color: var(--white);
}

/* Consulting CTA */

section.home-consulting-wrap {
  margin: 0;
  padding: 1.75rem 2rem;
  background: linear-gradient(
    to right,
    rgba(5, 10, 24, 0.98) 30%,
    rgba(0, 21, 79, 0.9)
  );
  background-size: cover;
  background-position: center 35%;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}

section.home-consulting-wrap .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section.home-consulting-wrap .container .cw-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

section.home-consulting-wrap .container h4 {
  font-family: var(--fbold);
  font-size: var(--font-28);
  color: var(--white);
  margin: 0.5rem 0;
  letter-spacing: normal;
}

section.home-consulting-wrap .container h4 span {
  font-family: inherit;
  color: var(--neon);
}

section.home-consulting-wrap .container h5 {
  font-family: var(--fregular);
  font-size: var(--font-16);
  max-width: 80%;
  color: var(--light-fa);
  line-height: 1.5;
}

section.home-consulting-wrap img {
  width: 5rem;
  max-width: 5rem;
}

section.home-consulting-wrap button.consult-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: var(--fbold);
  font-size: var(--font-16);
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-right: 2rem;
  background: var(--neon);
  border: 2px solid var(--white);
  transition: 0.18s ease;
  transform-origin: center;
  min-width: 100%;
  isolation: isolate;
  transform: scale3d(1, 1, 1);
  animation: pulse 1000ms ease infinite;
}

section.home-consulting-wrap button.consult-btn i {
  font-size: var(--font-18);
}

section.home-consulting-wrap button.consult-btn:hover {
  background: var(--white);
  color: var(--black-21);
}

@keyframes pulse {
  0% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
}

/* End of Consulting CTA */

/* Services */

section.home-wrapper section.services-wrap {
  padding: 0;
}

section.home-wrapper section.services-wrap .serv-left {
  margin: 1rem 0;
  padding-right: 2rem;
}

section.home-wrapper section.services-wrap .serv-left h2 {
  font-family: var(--fbold);
  font-size: 6rem;
  color: var(--black-21);
  line-height: 1.1;
}

section.home-wrapper section.services-wrap .serv-left h2 span {
  font-family: var(--fbold);
  color: var(--primary-red);
}

section.home-wrapper section.services-wrap .serv-left button.view-service-btn {
  font-family: var(--fbold);
  font-size: var(--font-14);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--neon);
  padding: 1rem 2rem;
  margin: 2rem 0 0 0.5rem;
  border: 2px solid var(--black-21);
  border-radius: 8px;
  transition: 0.18s ease;
}

section.home-wrapper
  section.services-wrap
  .serv-left
  button.view-service-btn:hover {
  background: var(--primary-blue);
  color: var(--white);
}

section.home-wrapper
  section.services-wrap
  .serv-left
  button.view-service-btn:hover
  i {
  color: var(--white);
}

section.home-wrapper
  section.services-wrap
  .serv-left
  button.view-service-btn
  i {
  font-size: var(--font-18);
  margin-top: 0.2rem;
}

section.home-wrapper section.services-wrap .serv-item-wrap {
  padding: 6rem 0;
}

section.home-wrapper section.services-wrap .serv-item-wrap .serv-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  background: var(--white);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 7px 20px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  margin: 2rem 0;
  width: 100%;
  height: 100%;
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item:first-child {
  margin-top: 0;
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item
  .serv-front {
  display: flex;
  align-items: center;
  gap: 20px;
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item
  .serv-icon {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  background: var(--primary-blue);
  border-radius: 8px;
  transition: 300ms ease;
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item:hover
  .serv-icon {
  background: var(--primary-red);
}

section.home-wrapper section.services-wrap .serv-item-wrap .serv-item box-icon {
  width: 100%;
  height: 100%;
  max-width: 4rem;
  max-height: 4rem;
  margin: 0;
  padding: 0.45rem;
  transition: 0.18s ease;
}

section.home-wrapper section.services-wrap .serv-item-wrap .serv-item h4 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black);
  padding-left: 0;
  margin: 0;
  line-height: 1.35;
  letter-spacing: -0.5px;
  transition: 0.18s ease;
}

section.home-wrapper section.services-wrap .serv-item-wrap .serv-item:hover h4 {
  color: var(--primary-red);
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item:hover
  box-icon {
  color: var(--primary-red);
}

/* 
section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item
  .serv-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.8rem;
  border: none;
  background: var(--primary-blue);
  transform: translateY(100%);
  transition: 0.2s ease;
} 

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item
  .serv-back
  p {
  font-family: var(--fmedium);
  font-size: 1.45rem;
  color: var(--white);
  margin: 0;
  line-height: 1.6;
} 

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item:hover
  .serv-back {
  transform: translateY(0);
} */

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item
  i.arrow-cta {
  font-size: var(--font-24);
  color: var(--black);
  transform: translateX(-1rem);
  transition: 0.18s ease;
}

section.home-wrapper
  section.services-wrap
  .serv-item-wrap
  .serv-item:hover
  i.arrow-cta {
  color: var(--primary-red);
  transform: translateX(0);
}

/* End of Services */

.cta-arrow-down {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  transform: translateY(2rem);
}

.cta-arrow-down span {
  font-family: var(--fbold);
  font-size: var(--font-12);
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 0.35rem;
}

/* Why Choose Us */

section.why-choose-us-wrap {
  position: relative;
  background: var(--white);
  z-index: 1;
  isolation: isolate;
  padding: 7rem 0 4rem;
}

section.why-choose-us-wrap .common-header {
  margin: 1rem 0 0;
  padding: 0;
}

section.why-choose-us-wrap h2 {
  position: relative;
  font-family: var(--fbold);
  font-size: 5rem;
  text-transform: uppercase;
}

section.why-choose-us-wrap h2::before {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  left: 0.25rem;
  background: var(--primary-red);
  width: 7rem;
  height: 0.6rem;
}

section.why-choose-us-wrap h2 span {
  font-family: var(--fbold);
  color: var(--primary-red);
}

section.why-choose-us-wrap p {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black-21);
  line-height: 1.75;
  margin: 5rem 0 1rem;
  max-width: 90%;
}

section.why-choose-us-wrap .wcu-item-wrap {
  padding: 1rem 2rem;
  background: var(--light-f5);
  border-radius: 16px;
}

section.why-choose-us-wrap .wcu-item {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  padding: 0;
  border-radius: 8px;
  transition: 0.2s ease;
  overflow: hidden;
  isolation: isolate;
  margin: 1rem 0;
  height: 25rem;
  min-height: 25rem;
  max-height: 25rem;
}

section.why-choose-us-wrap .wcu-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 241, 241, 0),
    rgba(2, 0, 44, 0.99) 95%
  );
}

section.why-choose-us-wrap .wcu-item:hover {
  box-shadow: 0 5px 25px 6px rgba(0, 0, 0, 0.1);
}

section.why-choose-us-wrap .wcu-item .wcu-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 1.5rem;
  margin: 0 auto;
}

section.why-choose-us-wrap .wcu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale3d(1, 1, 1);
  transition: transform 500ms ease;
}

section.why-choose-us-wrap .wcu-item:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.why-choose-us-wrap .wcu-item h5 {
  position: absolute;
  left: 2.25rem;
  bottom: 1.25rem;
  z-index: 2;
  display: block;
  font-family: var(--fbold);
  font-size: var(--font-18);
  color: var(--white);
  margin: 1.25rem 0;
  text-align: center;
  transition: 0.18s ease;
}

section.why-choose-us-wrap .wcu-item .wcu-back {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  transform: translateY(calc(-100% - 1rem));
  background: #1a2134;
  padding: 2.25rem;
  transition: 0.18s ease;
  border-radius: 0 0 16px 16px;
}

section.why-choose-us-wrap .wcu-item .wcu-back p {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--white);
  line-height: 1.52;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

section.why-choose-us-wrap .wcu-item:hover .wcu-back {
  transition-delay: 350ms;
  transform: translateY(0);
}

/* End of Why Choose Us */

/* About */

section.about-wrap {
  background: var(--light-f5);
  padding: 8rem 0 5rem;
}

section.about-wrap h2 {
  position: relative;
  font-family: var(--fbold);
  font-size: 5rem;
  text-transform: uppercase;
}

section.about-wrap h2::before {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  left: 0.2rem;
  background: var(--primary-red);
  width: 4.5rem;
  height: 0.6rem;
}

section.about-wrap h2 span {
  font-family: var(--fsemibold);
  color: var(--primary-red);
}

section.about-wrap h6 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--primary-red);
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  margin: 1rem 0 1.5rem;
}

section.about-wrap p {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black-21);
  line-height: 1.75;
  margin: 5rem 0 2rem;
}

section.about-wrap .abt-img-left {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
/* 
section.about-wrap .abt-img-left::before {
  content: "";
  position: absolute;
  top: -4.5rem;
  right: 3rem;
  z-index: 1;
  background: var(--primary-red);
  width: 0.75rem;
  height: 10rem;
} */

section.about-wrap img {
  width: 100%;
  max-width: 100%;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.about-wrap .abt-img-left:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.about-wrap .about-wrap-right {
  padding-left: 2.5rem;
}

/* End of About */

/* FAQ */

section.faq-section {
  max-width: 60%;
  margin: 0 auto;
  padding: 7rem 0;
}

section.faq-section h2 {
  font-size: var(--font-28);
  font-family: var(--fbold);
  text-align: center;
  margin-bottom: 4rem;
}

section.faq-section .faq-item-wrap {
  margin: 0;
  padding: 0;
}

section.faq-section .faq-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--black-21);
}

section.faq-section .faq-item:last-child {
  border-bottom: none;
}

section.faq-section .faq-item h4 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  margin-bottom: 1.25rem;
}

section.faq-section .faq-item h6 {
  font-family: var(--fregular);
  font-size: var(--font-16);
  line-height: 1.6;
}

/* End of FAQ */

/* Rating */

section.rating-section {
  position: relative;
  padding: 0;
  background: url("../../../images/rating_banner.jpg");
  background-size: contain;
  background-position: center;
  height: 100%;
  min-height: 50vh;
}

section.rating-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(2, 0, 69, 0.7),
    rgba(3, 1, 71, 0.85) 80%
  );
}

section.rating-section .rating-text {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  height: 100%;
  min-height: 50vh;
}

section.rating-section h2 {
  font-family: var(--fbold);
  font-size: 3.75rem;
  color: var(--white);
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

section.rating-section h5 {
  font-family: var(--fbold);
  font-size: 5rem;
  color: #ffc107;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin: 3.5rem 0 0;
}

section.rating-section h5 img {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
}

/* End of Rating */

/***** END OF HOME STYLES *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  /* Header Text */

  section.home-wrapper {
    padding: 45% 0 1rem;
    background-image: linear-gradient(
        to right,
        rgba(2, 0, 69, 0.95),
        rgba(180, 0, 0, 0.5) 80%
      ),
      url("../../../images/herobg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 42% top;
  }

  section.home-wrapper .text-content {
    padding-inline: 0.75rem;
  }

  section.home-wrapper .text-content h1 {
    font-size: 2.8rem;
    max-width: 100%;
    margin-top: 1rem;
  }

  section.home-wrapper .text-content span[data-testid="react-typed"] span {
    font-size: 3.15rem;
    white-space: nowrap;
    padding-inline: 0.3rem;
  }

  section.home-wrapper .text-content h6 {
    font-size: 1.75rem;
    max-width: 90%;
    line-height: 1.5;
  }

  section.home-wrapper .hero-cta-wrap {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0 0;
  }

  section.home-wrapper .hero-cta-wrap a {
    display: block;
    width: 100%;
  }

  section.home-wrapper .hero-cta-wrap button.hero-cta-btn {
    justify-content: center;
    width: 100%;
    font-size: var(--font-18);
  }

  section.home-wrapper .hero-cta-wrap button.hero-cta-btn.whatsapp {
    width: 100%;
  }

  .cta-arrow-down {
    transform: translateY(0);
  }

  /* End of Header Text */

  /* Home Consulting */

  section.home-consulting-wrap {
    padding: 2rem 1rem;
  }

  section.home-consulting-wrap .container {
    flex-direction: column;
    align-items: flex-start;
  }

  section.home-consulting-wrap .container .cw-left {
    gap: 10px;
  }

  section.home-consulting-wrap .container h4 {
    font-size: var(--font-20);
    margin: 0;
  }

  section.home-consulting-wrap a {
    width: 100%;
    display: block;
  }

  section.home-consulting-wrap button.consult-btn {
    width: 100%;
    font-size: var(--font-14);
    margin: 2rem 0 0;
  }

  /* End of Home Consulting */

  section.home-wrapper section.services-wrap .serv-item-wrap {
    padding-bottom: 0;
  }

  section.home-wrapper section.services-wrap .serv-item-wrap .serv-item {
    margin: 0.5rem 0;
  }

  section.home-wrapper section.services-wrap .serv-item-wrap .serv-item h4 {
    font-family: var(--fbold);
    font-size: 1.5rem;
  }

  section.floating-menu {
    display: none;
  }
  /* Why Choose us */
  section.why-choose-us-wrap {
    padding: 3rem 1rem;
  }

  section.why-choose-us-wrap h2 {
    font-size: 3.5rem;
  }

  section.why-choose-us-wrap p {
    font-size: var(--font-16);
    line-height: 1.4;
    max-width: 100%;
  }

  section.why-choose-us-wrap .wcu-item-wrap {
    padding: 1rem;
  }

  section.why-choose-us-wrap .wcu-item-wrap .wcu-item {
    margin: 0.5rem 0;
    height: 20rem;
    min-height: 20rem;
    max-height: 20rem;
  }

  section.why-choose-us-wrap .wcu-item-wrap .wcu-item h5 {
    font-size: var(--font-16);
  }

  section.why-choose-us-wrap .wcu-item .wcu-back p {
    font-size: 1.5rem;
    line-height: 1.7;
  }

  /* End of Why Choose us */

  /* Home About */

  section.about-wrap {
    padding: 2rem 1rem 0;
  }

  section.about-wrap h2 {
    font-size: 3.5rem;
  }

  section.about-wrap .about-wrap-right {
    padding: 1.5rem 0;
  }

  section.about-wrap .about-wrap-right h6 {
    font-size: var(--font-14);
  }

  section.about-wrap .about-wrap-right p {
    font-size: var(--font-16);
    line-height: 1.4;
    max-width: 100%;
  }

  /* End of Home About */

  /* FAQ */

  section.faq-section {
    max-width: 100%;
    margin: 0 auto;
    padding: 3rem 1.75rem;
  }

  section.faq-section h2 {
    font-size: var(--font-24);
    text-align: left;
    margin-bottom: 1rem;
  }

  section.faq-section .faq-item h4 {
    font-size: var(--font-16);
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  section.faq-section .faq-item h6 {
    font-size: var(--font-14);
    line-height: 1.6;
  }
  /* End of FAQ */
  /* Rating */

  section.rating-section {
    position: relative;
    padding: 0;
    height: 100%;
    min-height: 30vh;
  }

  section.rating-section .rating-text {
    min-height: 30vh;
  }

  section.rating-section h2 {
    font-family: var(--fbold);
    font-size: 2.25rem;
    color: var(--white);
    line-height: 1.4;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  section.rating-section h5 {
    gap: 10px;
    font-size: 3rem;
    margin-top: 1.5rem;
  }

  section.rating-section h5 img {
    width: 3rem;
    min-width: 3rem;
    max-width: 3rem;
  }

  /* End of Rating */
}

/***** END OF MEDIA QUERIES  *****/
