/***** ABOUT *****/

section.about-wrapper .abt-header {
  margin: 2rem 0 0;
  padding: 2rem 0 1rem;
}

section.about-wrapper .abt-content {
  padding: 2.5rem;
}

section.about-wrapper .abt-body {
  margin: 5rem 0 0;
}

section.about-wrapper .abt-body p,
section.about-wrapper p {
  font-family: var(--fmedium);
  font-size: var(--font-18);
  color: var(--black-21);
  line-height: 1.75;
  padding: 1rem 0;
  margin: 0;
}

section.about-wrapper .abt-img-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
  overflow: hidden;
}
/* 
section.about-wrapper .abt-img-left::before {
  content: "";
  position: absolute;
  top: -4.5rem;
  right: 3rem;
  z-index: 1;
  background: var(--primary-red);
  width: 0.75rem;
  height: 10rem;
} */

section.about-wrapper .abt-img-left img {
  max-width: 100%;
  max-height: 100%;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.about-wrapper .abt-img-left:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.about-wrapper .mvv-items-wrap {
  margin: 3rem 0 5rem;
}

section.about-wrapper .mvv-items-wrap .mvv-item {
  height: 100%;
  padding: 0;
  border-radius: 16px;
  background: var(--light-f5);
  overflow: hidden;
  transition: 0.18s ease all;
}

section.about-wrapper .mvv-items-wrap .mvv-item:hover {
  background: var(--white);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-img {
  width: 100%;
  overflow: hidden;
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-img img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-img:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-body {
  height: 100%;
  padding: 2.5rem;
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-body h4 {
  font-family: var(--fbold);
  font-size: var(--font-20);
}

section.about-wrapper .mvv-items-wrap .mvv-item:hover h4 {
  color: var(--primary-red);
}

section.about-wrapper .mvv-items-wrap .mvv-item .mvv-body p {
  font-family: var(--fregular);
  font-size: 1.7rem;
  color: var(--black-21);
  line-height: 1.5;
  margin: 0;
}

/***** END OF ABOUT *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.about-wrapper .abt-header {
    padding: 1rem;
  }
  section.about-wrapper .abt-content {
    padding: 0;
  }
  section.about-wrapper .abt-body {
    margin-top: 3.5rem;
  }
  section.about-wrapper .abt-body p,
  section.about-wrapper p {
    font-size: var(--font-16);
    line-height: 1.5;
  }
  section.about-wrapper .mvv-items-wrap .mvv-item {
    height: calc(100% - 2rem);
    margin-bottom: 2rem;
  }
  section.about-wrapper .mvv-items-wrap .mvv-item:last-child {
    margin-bottom: 0;
  }
}

/***** END OF MEDIA QUERIES  *****/
