/* Font Imports */

@font-face {
  font-family: "DMSans-Light";
  src: url("./fonts/DMSans/DMSans-Light.ttf");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./fonts/DMSans/DMSans-Regular.ttf");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./fonts/DMSans/DMSans-Medium.ttf");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./fonts/DMSans/DMSans-SemiBold.ttf");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("./fonts/DMSans/DMSans-Bold.ttf");
}

@font-face {
  font-family: "DMSans-ExtraBold";
  src: url("./fonts/DMSans/DMSans-ExtraBold.ttf");
}

@font-face {
  font-family: "DMSans-Black";
  src: url("./fonts/DMSans/DMSans-Black.ttf");
}

@font-face {
  font-family: "Alata-Regular";
  src: url("./fonts/Alata-Regular.ttf");
}

/* End of Font Imports */

/* Root */

:root {
  /* Fonts */
  --flight: "DMSans-Light";
  --fregular: "DMSans-Regular";
  --fmedium: "DMSans-Medium";
  --fsemibold: "DMSans-SemiBold";
  --fbold: "DMSans-Bold";
  --fextrabold: "DMSans-ExtraBold";
  --fblack: "DMSans-Black";
  --freg: "Alata-Regular";
  /* Font Size */
  --font-10: 1rem;
  --font-12: 1.2rem;
  --font-14: 1.4rem;
  --font-16: 1.6rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-22: 2.2rem;
  --font-24: 2.4rem;
  --font-26: 2.6rem;
  --font-28: 2.8rem;
  --font-30: 3rem;
  /* Colors */
  --primary-blue: #1a2134;
  --primary-red: #f10b0b;
  --neon: #bdff00;
  --teal: teal;
  --red: #ff0000;
  --black: #000;
  --black-30: #303030;
  --black-42: #424242;
  --black-12: #121212;
  --black-2e: #2e2e2e;
  --black-24: #242424;
  --black-1e: #1e1d1b;
  --black-20: #202b27;
  --black-21: #212121;
  --black-61: #616161;
  --black-75: #757575;
  --black-9e: #9e9e9e;
  --white: #fff;
  --light-fa: #fafafa;
  --light-e0: #e0e0e0;
  --light-ee: #eeeeee;
  --light-f5: #f5f5f5;
  --light-fc: #fcfcfc;
}

/* End of Root */

/* Reset Styles */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DMSans-Regular", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

/* End of Reset Styles */

/* Custom Scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* End of Custom Scrollbar */

/* Common Styles */

p {
  font-size: var(--font-14);
  line-height: 2.4rem;
}

a {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

section.section-wrapper {
  padding: 6rem 0;
}

section.common-wrapper {
  padding: calc(9rem + 4rem) 0 2rem;
}

section.common-wrapper .common-header {
  margin: 4rem 0 2rem;
}

section.common-wrapper h2 {
  position: relative;
  font-family: var(--fbold);
  font-size: 5rem;
  text-transform: uppercase;
}

section.common-wrapper h2::before {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  left: 0.2rem;
  background: var(--primary-red);
  width: 4.5rem;
  height: 0.6rem;
}

section.common-wrapper h2 span {
  font-family: var(--fbold);
  color: var(--primary-red);
}

section.common-wrapper h6 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--primary-red);
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  margin: 1rem 0 1.5rem;
}

button.section-btn {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  background: var(--neon);
  border: 2px solid var(--black-21);
  outline: none;
  margin: 2rem 0 0;
  transition: 0.18s ease;
}

button.section-btn i {
  font-size: var(--font-18);
  margin-top: 0.1rem;
}

button.section-btn:hover {
  background: var(--black-21);
  color: var(--white);
}

/* React Select */

.input__select__control {
  font-family: var(--fmedium) !important;
  font-size: var(--font-14) !important;
  box-shadow: none !important;
  cursor: pointer !important;
  min-height: 4.4rem !important;
  letter-spacing: -0.01rem !important;
  border-radius: 5px !important;
  background: rgba(26, 33, 52, 0.03) !important;
  border-width: 2px !important;
  outline-width: 2px !important;
  border-color: var(--light-e0) !important;
}

.input__select__control:focus {
  box-shadow: none !important;
}

.input__select__control--is-focused {
  outline-color: var(--primary-blue) !important;
  border-color: var(--primary-blue) !important;
  background: var(--white) !important;
}

.input__select__input-container,
.input__select__placeholder,
.input__select__single-value {
  padding-left: 0.5rem !important;
}

.input__select__placeholder {
  font-family: var(--fmedium) !important;
  color: var(--black-75) !important;
  letter-spacing: -0.02rem !important;
}

.input__select__indicator-separator {
  display: none !important;
}

/* End of React Select */

.custom-toast {
  min-width: 30rem !important;
  top: 7% !important;
}

.custom-toast .go2072408551 {
  gap: 1.5rem !important;
  max-width: 45rem !important;
}

.custom-toast .go3958317564 {
  font-family: var(--fbold) !important;
  padding-left: -1.5rem !important;
}

.modal-backdrop {
  opacity: 0.75;
}

/* End of Common Styles */

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.common-wrapper h2 {
    font-size: 3.5rem;
  }
  section.section-wrapper {
    padding: 3rem 0;
  }
  button.section-btn {
    font-size: var(--font-14);
    margin: 1.5rem 0 2rem;
  }

  section.common-wrapper .common-header {
    margin: 3rem 0 2rem;
    padding: 1rem;
  }

  section.common-wrapper h6 {
    font-size: var(--font-14);
  }
}

/***** END OF MEDIA QUERIES  *****/
