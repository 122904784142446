/* Home Security */

.home-security-wrapper {
  padding: calc(9rem + 2.2rem + 3rem) 0 3rem;
}

.home-security-wrapper .hs-content {
  padding: 4rem 0;
}

.home-security-wrapper img.hs_img {
  max-width: 100%;
  border-radius: 16px;
}

.home-security-wrapper .hsw-text {
  padding: 1rem 3.5rem 1rem 0;
}

.home-security-wrapper .hsw-text h2 {
  font-family: var(--fbold);
  font-size: 3.25rem;
  margin: 0 0 1.25rem 0;
}

.home-security-wrapper .hsw-text h3 {
  position: relative;
  font-family: var(--fbold);
  font-size: var(--font-16);
  color: green;
  margin: 0 0 1.25rem 1.5rem;
}

.home-security-wrapper .hsw-text h3::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  aspect-ratio: 1;
  background: green;
}

.home-security-wrapper .hsw-text h4 {
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  background: var(--primary-blue);
  color: var(--white);
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.home-security-wrapper .hsw-text h6 {
  font-family: var(--fregular);
  font-size: var(--font-16);
  line-height: 1.65;
}

.home-security-wrapper .hsw-text h5.hsw-head {
  font-family: var(--fbold);
  font-size: var(--font-16);
  margin: 3rem 0;
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 3rem 0;
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: var(--light-f5);
  padding: 1.25rem;
  border-radius: 8px;
  border: 1px solid var(--light-e0);
  transition: 300ms ease;
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item:hover {
  border-color: var(--primary-blue);
  background: var(--light-ee);
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item:hover h5 {
  color: var(--primary-blue);
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item .hsw-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1;
  background: var(--light-ee);
  border-radius: 8px;
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item img {
  max-width: 2rem;
  min-width: 2rem;
}

.home-security-wrapper .hsw-text .hsw-highlight-item-wrap .hsw-item h5 {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--black-31);
  margin: 0;
}

.home-security-wrapper .hsw-text a.hs-enquire {
  display: inline-block;
}

.home-security-wrapper .hsw-text button.section-btn:hover {
  background: var(--neon);
  color: var(--black);
}

/* End of Home Security */
