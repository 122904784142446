/***** CAREERS  *****/

section.careers-wrapper .careers-header {
  margin: 4rem 0;
  padding-right: 2rem;
}

section.careers-wrapper .intern-banner {
  position: relative;
  height: 100%;
  padding: 0;
  background: none;
  margin: 1rem 0 0 2rem;
  border-radius: 16px;
}

section.careers-wrapper .intern-banner::before {
  content: "";
  position: absolute;
  top: -4.5rem;
  left: 9rem;
  z-index: 2;
  background: var(--primary-red);
  width: 0.75rem;
  height: 10rem;
}

section.careers-wrapper .intern-banner img {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  object-fit: contain;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.careers-wrapper .intern-banner:hover img {
  transform: scale3d(1.03, 1.03, 1.03);
}

section.careers-wrapper .careers-header h4 {
  font-family: var(--fblack);
  font-size: 5rem;
  color: var(--black-21);
  letter-spacing: normal;
  line-height: 1.25;
  margin: 2rem 0;
  text-transform: uppercase;
}

section.careers-wrapper .careers-header h4 span {
  font-family: inherit;
  color: var(--primary-red);
}

section.careers-wrapper .careers-header h5 {
  font-family: var(--fextrabold);
  font-size: var(--font-20);
  color: var(--primary-red);
  letter-spacing: normal;
  line-height: 1.25;
  margin: 1rem 0 2rem;
}

section.careers-wrapper .careers-header p {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black-21);
  text-transform: initial;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 0;
}

section.careers-wrapper .careers-header h6 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--primary-red);
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  margin: 1rem 0 1.5rem;
}

section.careers-wrapper .resumes-email {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 1.5rem;
  background: #ffebeb;
  border-radius: 8px;
  border: 2px dashed var(--primary-red);
  margin: 3rem 0 0;
}

section.careers-wrapper .resumes-email i {
  font-size: var(--font-30);
  color: var(--primary-red);
  margin-top: 0.1rem;
}

section.careers-wrapper .resumes-email h5 {
  font-family: var(--fbold);
  font-size: var(--font-18);
  color: var(--black-21);
  margin: 1rem 0;
  line-height: 1.3;
}

section.careers-wrapper .resumes-email h5 span {
  font-family: var(--fbold);
  color: var(--primary-red);
  margin: 0;
}

section.careers-wrapper h3 {
  font-family: var(--fbold);
  font-size: var(--font-20);
  color: var(--black-21);
  margin: 1.5rem 0;
}

section.careers-wrapper .careers-item-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 2rem 0;
}

section.careers-wrapper .careers-item-wrap .carr-item {
  padding: 1.75rem;
  background: var(--light-fa);
  border-radius: 8px;
  transition: 0.18s ease;
  margin-bottom: 2rem;
  flex-basis: 100%;
  border: none;
  border-top: 4px solid transparent;
  transition: 0.18s ease;
}

section.careers-wrapper .careers-item-wrap .carr-item:hover {
  background: var(--white);
  box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.2);
  border-top: 4px solid var(--primary-red);
  animation: changeColor 1500ms forwards ease;
}

@keyframes changeColor {
  from {
    border-top: 4px solid var(--primary-red);
    box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.2);
  }
  to {
    border-top: 4px solid #228c22;
    box-shadow: 0 15px 25px 0 rgba(34, 140, 34, 0.2);
  }
}

section.careers-wrapper .careers-item-wrap .carr-item h4 {
  font-family: var(--fsemibold);
  font-size: var(--font-18);
  color: var(--black-61);
  margin: 0;
}
section.careers-wrapper .careers-item-wrap .carr-item:hover h4 {
  color: var(--black);
}

section.careers-wrapper .careers-item-wrap .carr-item h6 {
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  color: var(--primary-red);
  text-transform: initial;
  letter-spacing: -0.02rem;
  margin: 0.75rem 0;
}

section.careers-wrapper .careers-item-wrap .carr-item:hover h6 {
  color: var(--primary-red);
  animation: changeTextColor 1500ms forwards ease;
}

@keyframes changeTextColor {
  from {
    color: var(--primary-red);
  }
  to {
    color: #228c22;
  }
}

section.careers-wrapper .careers-item-wrap .carr-item h5 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--fmedium);
  font-size: var(--font-14);
  color: var(--black-61);
  margin: 1.5rem 0 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--light-e0);
}

section.careers-wrapper .careers-item-wrap .carr-item h5 i {
  font-size: var(--font-20);
}

section.careers-wrapper .careers-item-wrap .carr-item p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  color: var(--black-21);
  margin: 0;
}

section.careers-wrapper .role-item-wrap {
  margin-bottom: 3rem;
}

section.careers-wrapper ul.role-item {
  margin: 0;
  padding: 0;
  list-style: none;
}

section.careers-wrapper ul li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: var(--fmedium);
  font-size: var(--font-16);
  color: var(--black);
  padding: 0.5rem 0;
}

section.careers-wrapper ul li i {
  font-size: var(--font-18);
  color: var(--teal);
}

/***** END OF CAREERS  *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.careers-wrapper .careers-header {
    margin: 3.5rem 0 2rem;
    padding: 1rem;
  }
  section.careers-wrapper .careers-header h6 {
    font-size: var(--font-14);
    line-height: 1.5;
  }
  section.careers-wrapper .careers-header h4 {
    font-size: 3.5rem;
  }
  section.careers-wrapper .careers-header h5 {
    font-size: var(--font-16);
    line-height: 1.5;
  }
  section.careers-wrapper .careers-header p {
    font-size: var(--font-14);
    line-height: 1.6;
    max-width: 100%;
  }
  section.careers-wrapper .resumes-email {
    gap: 10px;
    margin: 1.75rem 0;
    padding: 1rem;
    flex-direction: column;
  }
  section.careers-wrapper .resumes-email h5 {
    font-size: 1.55rem;
    margin: 0;
    text-align: center;
  }
  section.careers-wrapper .intern-banner {
    margin: 2rem 0 0;
  }
  section.careers-wrapper .intern-banner::before {
    top: -1.5rem;
    left: 2rem;
    height: 5rem;
  }
  section.careers-wrapper .intern-banner img {
    max-width: 100%;
  }
  section.careers-wrapper h3 {
    padding-left: 1rem;
    margin: 0;
  }
  section.careers-wrapper .careers-item-wrap {
    flex-direction: column;
    padding: 1rem;
    margin: 1rem 0;
  }
  section.careers-wrapper .careers-item-wrap .carr-item {
    width: 100%;
    margin-bottom: 0;
    padding: 1.25rem;
  }
  section.careers-wrapper .careers-item-wrap .carr-item h4 {
    font-size: var(--font-18);
  }
  section.careers-wrapper .careers-item-wrap .carr-item p {
    font-size: var(--font-14);
  }
}

/***** END OF MEDIA QUERIES  *****/
