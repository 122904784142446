/***** SERVICES *****/

section.services-wrapper p {
  font-family: var(--fmedium);
  font-size: var(--font-18);
  margin: 6rem 1rem 3rem 0;
  line-height: 1.75;
  max-width: 90%;
}

section.services-wrapper .serv-img {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
/* 
section.services-wrapper .serv-img::before {
  content: "";
  position: absolute;
  top: 3rem;
  left: -4.5rem;
  z-index: 1;
  background: var(--primary-red);
  width: 10rem;
  height: 0.75rem;
} */

section.services-wrapper .serv-img img {
  max-width: 100%;
  transform: scale3d(1, 1, 1);
  transition: 500ms ease transform;
}

section.services-wrapper .serv-img:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

section.services-wrapper .services-list-wrap {
  padding: 4rem 0 1rem;
}

section.services-wrapper .services-list-wrap .serv-list-item {
  position: relative;
  padding: 2.5rem;
  border-radius: 8px;
  background: var(--light-fa);
  height: calc(100% - 2.5rem);
  min-height: 30rem;
  margin-bottom: 4rem;
  transition: 0.18s ease;
  border-top: 4px solid transparent;
}

section.services-wrapper .services-list-wrap .serv-list-item.highlighted {
  min-height: auto;
}

section.services-wrapper .services-list-wrap .serv-list-item:hover {
  background: var(--white);
  box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.2);
  border-top: 4px solid var(--primary-red);
  animation: changeColor 1500ms forwards ease;
}

@keyframes changeColor {
  from {
    border-top: 4px solid var(--primary-red);
    box-shadow: 0 15px 25px 0 rgba(241, 11, 11, 0.2);
  }
  to {
    border-top: 4px solid #228c22;
    box-shadow: 0 15px 25px 0 rgba(34, 140, 34, 0.2);
  }
}

section.services-wrapper .services-list-wrap .serv-list-head {
  padding: 0;
}
section.services-wrapper .services-list-wrap .serv-list-head .serv-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  min-width: 4.8rem;
  min-height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  border-radius: 50%;
  position: absolute;
  top: -1.15rem;
  right: 1rem;
  transform: translateX(-50%);
  background: rgba(241, 11, 11, 1);
  outline: 5px solid var(--white);
  padding: 0.5rem;
  transition: 0.2s ease;
}

section.services-wrapper .services-list-wrap .serv-list-item:hover .serv-icon {
  top: -2rem;
  background: var(--red);
  /* animation: changeIconColor 1500ms forwards ease; */
}
/* 
@keyframes changeIconColor {
  from {
    background: var(--red);
  }
  to {
    background: #228c22;
  }
} */

section.services-wrapper .services-list-wrap .serv-list-item .serv-icon i {
  color: var(--white);
  font-size: var(--font-28);
  margin: 0;
}

section.services-wrapper .services-list-wrap .serv-list-head h3 {
  font-family: var(--fbold);
  font-size: var(--font-20);
  color: var(--black-21);
  margin: 0 0 0.75rem 0;
  letter-spacing: -0.05rem;
  transition: 0.2s ease;
  max-width: 80%;
  line-height: 1.35;
}

section.services-wrapper .services-list-wrap .serv-list-item:hover h3 {
  color: var(--primary-red);
  animation: changeTextColor 1500ms forwards ease;
}

@keyframes changeTextColor {
  from {
    color: var(--primary-red);
  }
  to {
    color: #228c22;
  }
}

section.services-wrapper .services-list-wrap .serv-list-head h4 {
  font-family: var(--fmedium);
  font-size: 1.65rem;
  color: var(--black-2e);
  margin: 1rem 0;
  line-height: 1.4;
}

section.services-wrapper .services-list-wrap .serv-list-item:hover h4 {
  color: var(--black);
}

section.services-wrapper .services-list-wrap .serv-list-head h6 {
  font-family: var(--fmedium);
  font-size: var(--font-12);
  color: var(--black-75);
  letter-spacing: 1px;
}

section.services-wrapper .services-list-wrap .serv-list-head h6 span {
  display: block;
  font-family: var(--fbold);
  font-size: var(--font-20);
  color: var(--primary-red);
  margin: 0.5rem 0;
  letter-spacing: normal;
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item
  .serv-list-content {
  padding: 0;
  border-radius: 8px;
  margin: 2rem 0 0;
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item
  .serv-list-content
  .sr-rt-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: var(--white);
  border-radius: 8px;
  margin: 0.75rem 0;
  transition: 0.18s ease;
}

section.services-wrapper .services-list-wrap .serv-list-item:hover .sr-rt-item {
  background: #ffebeb;
  animation: changeBgColor 1500ms forwards ease;
}

@keyframes changeBgColor {
  from {
    background: #ffebeb;
  }
  to {
    background: rgba(34, 140, 34,0.1);
  }
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item
  .serv-list-content
  .sr-rt-item
  h5 {
  font-family: var(--fsemibold);
  font-size: var(--font-14);
  color: var(--black-21);
  margin: 0;
  letter-spacing: -0.02rem;
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item
  .serv-list-content
  .sr-rt-item
  h4 {
  font-family: var(--fbold);
  font-size: var(--font-16);
  color: var(--primary-red);
  margin: 0;
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item:hover
  .sr-rt-item
  h4 {
  color: var(--primary-red);
  animation: changePriceColor 1500ms forwards ease;
}

@keyframes changePriceColor {
  from {
    color: var(--primary-red);
  }
  to {
    color: #228c22;
  }
}

section.services-wrapper
  .services-list-wrap
  .serv-list-item
  .serv-list-content
  .sr-rt-item
  h4
  a {
  font-family: var(--fbold);
  color: var(--primary-blue);
}

/***** END OF SERVICES *****/

/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.services-wrapper .services-list-wrap {
    padding: 1.5rem 1rem 0;
  }
  section.services-wrapper .services-list-wrap .serv-list-item {
    padding: 1.5rem;
  }
  section.services-wrapper .services-list-wrap .serv-list-head .serv-icon {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
  }
  section.services-wrapper .services-list-wrap .serv-list-item .serv-icon i {
    font-size: var(--font-22);
  }
  section.services-wrapper .services-list-wrap .serv-list-head h3 {
    font-size: var(--font-18);
  }
  section.services-wrapper .services-list-wrap .serv-list-head h4 {
    font-size: var(--font-14);
    line-height: 1.5;
  }
  section.services-wrapper p {
    font-size: var(--font-16);
    max-width: 100%;
    margin: 4.5rem 0 2.5rem;
  }
}

/***** END OF MEDIA QUERIES  *****/
