section.legal-section-wrapper {
  padding: 12% 0 5rem;
  background: var(--light-fa);
}

section.legal-section-wrapper .container.custom {
  max-width: 60%;
  margin: 0 auto;
}

section.legal-section {
  padding: 1rem 0;
}

section.legal-section .legal-wrap h2 {
  position: relative;
  padding: 0;
  margin: 3rem 0;
  z-index: 1;
  display: inline-block;
  position: relative;
  font-family: var(--fsemibold);
  font-size: var(--font-24);
}

section.legal-section .legal-wrap h2 span {
  font-family: var(--fregular);
  font-size: 2.5rem;
  color: var(--primary-red);
}

section.legal-section .legal-wrap h2::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 56px;
  height: 2px;
  background: var(--primary-red);
}

section.legal-wrap p,
section.legal-wrap span,
section.legal-wrap ul li {
  color: var(--black-61);
}

section.legal-section p {
  font-size: var(--font-16);
  line-height: 1.75;
}

section.legal-section span {
  font-size: var(--font-16);
}

section.legal-section h5 {
  font-family: var(--fsemibold);
  font-size: var(--font-16);
  margin: 1.5rem 0;
}

section.legal-section ul {
  list-style: disc;
  padding: 1rem 0 0 1.5rem;
  margin-bottom: 1em;
}

section.legal-section ul li {
  padding-bottom: 0.75rem;
  font-size: var(--font-16);
}

section.legal-section ul li::first-letter {
  text-transform: capitalize;
}

section.legal-section .legal-wrap span.highlight {
  font-family: var(--fbold);
  color: var(--primary-red);
}

section.legal-section span.legal-link {
  color: var(--bs-blue);
  cursor: pointer;
}

section.legal-section-wrapper .nav {
  border: none;
  column-gap: 30px;
}

section.legal-section-wrapper .nav .nav-item {
  border: none;
  cursor: pointer;
  padding: 0;
}

section.legal-section-wrapper .nav .nav-item .nav-link {
  position: relative;
  font-family: var(--fmedium);
  font-size: var(--font-14);
  color: var(--black-61);
  border: none;
  border-radius: 5px;
  padding-inline: 0;
  background: none;
}

section.legal-section-wrapper .nav .nav-item .nav-link.active {
  color: var(--black);
}

section.legal-section-wrapper .nav .nav-item .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: -0.35rem;
  left: 0;
  width: 100%;
  height: 0.3rem;
  background: var(--primary-red);
  border-radius: 8px 8px 0 0;
}
/***** MEDIA QUERIES  *****/

@media only screen and (max-width: 600px) {
  section.legal-section-wrapper {
    padding: 30% 0 3rem;
  }
  section.legal-section-wrapper .container.custom {
    width: 100%;
    max-width: 100%;
    padding: 1.5rem;
  }
  section.legal-section .legal-wrap h2 {
    margin-top: 1rem;
  }
  section.legal-section p,
  section.legal-section ul li,
  section.legal-section span {
    font-size: var(--font-14);
  }
  section.legal-section-wrapper .container.custom .nav.nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2rem 0;
  }
  section.legal-section-wrapper
    .container.custom
    .nav.nav-tabs::-webkit-scrollbar {
    height: 0;
  }
  section.legal-section-wrapper
    .container.custom
    .nav.nav-tabs
    .nav-item
    .nav-link {
    white-space: nowrap;
  }
  section.legal-section-wrapper .nav .nav-item .nav-link.active::before {
    bottom: -0.1rem;
}
}

/***** END OF MEDIA QUERIES  *****/
